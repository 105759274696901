import { db, pdb } from "../../firebaseDb";
//import Vue from 'vue'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  limit
} from "firebase/firestore";

// Returns the ISO week of the date.
Date.prototype.getWeek = function () {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

// Returns the four-digit year corresponding to the ISO week of the date.
Date.prototype.getWeekYear = function () {
  var date = new Date(this.getTime());
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  return date.getFullYear();
}

var dateObj = new Date();
var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
var year = dateObj.getFullYear();
var day = dateObj.getDate();
var hour = dateObj.getHours();
//var wyear = new Date(dateObj.getFullYear(), 0, 1);
//var days = Math.floor((dateObj - wyear) / (24 * 60 * 60 * 1000));
//var week = Math.ceil((dateObj.getDay() + 1 + days) / 7);

let today = year + "-" + month + "-" + String(day).padStart(2, '0')

let week = dateObj.getWeek();

console.log(week);
if (day == 1 && hour < 10) {
  month = ('0' + (dateObj.getMonth())).slice(-2);
}

const state = {
  allchains: [],
  loading: false,
  currWeek: week,
  currMon: dateObj.getMonth() + 1,
  currYear: year,
  yearmon: year + month,
  yearweek: String(year) + String(week).padStart(2, '0'),
  selectedBeeprPath: "",
  selectedPath: "",
  selectedPathSuffix: "",
  client: "",
  clientData: {},
  beeprData: {},
  current: [],
  newcurrent: [],
  noprice: [],
  notfound: [],
  offer: [],
  lower: [],
  higher: [],
  gone: [],
  new: [],
  prices: {},
  productprices: {},
  level: "Cat1",
  product: {},
  products: [],
  comptype: "",
  allowedEAN: "",
  ppm: {},
  ppb: {},
  history: {},
  shorthistory: {},
  cat1Stats: {}
};
const getters = {
  allchains(state) {
    return state.allchains;
  },
  loading(state) {
    return state.loading;
  },
  ppm(state) {
    return state.ppm;
  },
  ppb(state) {
    return state.ppb;
  },
  history(state) {
    return state.history;
  },
  shorthistory(state) {
    return state.shorthistory;
  },
  allowedEAN(state) {
    return state.allowedEAN;
  },
  comptype(state) {
    return state.comptype;
  },
  current(state) {
    return state.current;
  },
  newcurrent(state) {
    return state.newcurrent;
  },
  noprice(state) {
    return state.noprice;
  },
  notfound(state) {
    return state.notfound;
  },
  offer(state) {
    return state.offer;
  },
  lower(state) {
    return state.lower;
  },
  higher(state) {
    return state.higher;
  },
  gone(state) {
    return state.gone;
  },
  new(state) {
    return state.new;
  },
  prices(state) {
    return state.prices;
  },
  productprices(state) {
    return state.productprices;
  },
  client(state) {
    return state.client;
  },
  clientData(state) {
    return state.clientData;
  },
  beeprData(state) {
    return state.beeprData;
  },
  yearmon(state) {
    return state.yearmon;
  },
  currWeek(state) {
    return state.currWeek;
  },
  currMon(state) {
    return state.currMon;
  },
  currYear(state) {
    return state.currYear;
  },
  selectedBeeprPath(state) {
    return state.selectedBeeprPath;
  },
  selectedPath(state) {
    return state.selectedPath;
  },
  selectedPathSuffix(state) {
    return state.selectedPathSuffix;
  },
  level(state) {
    return state.level;
  },
  product(state) {
    return state.product;
  },
  products(state) {
    return state.products;
  },
  cat1Stats(state) {
    return state.cat1Stats;
  }
};
const actions = {
  setProduct({ commit }, product) {
    commit("SET_PRODUCT", ean)
  },
  setYearmon({ commit }, yearmon) {
    commit("SET_YEARMON", yearmon)
  },
  setAllowedEAN({ commit }, allowedean) {
    commit("SET_ALLOWED_EAN", allowedean)
  },
  setLevel({ commit }, level) {
    commit("SET_LEVEL", level)
  },
  setSelectedPath({ commit }, path) {
    commit("SET_SELECTED_PATH", path)
  },
  setSelectedBeeprPath({ commit }, path) {
    commit("SET_SELECTED_BEEPR_PATH", path)
  },

  setClientData({ state, commit }) {
    const docRef = doc(db, "clients/" + state.client);

    return new Promise((resolve, reject) => {
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        let clientdata = docSnap.data();
        //console.log(clientdata);
        commit("SET_CLIENT_DATA", clientdata)
        resolve(clientdata);
      } else {
        reject();
      }
    });
  });
  },

  setBeeprData({ commit }) {
    const docRef = doc(db, "clients/beepr");

    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        let clientdata = docSnap.data();
        //console.log(clientdata);
        commit("SET_BEEPR_DATA", clientdata)
      }
    });
  },

  async setAllChains({ commit }) {
    let tmpArr = [];
    let q = query(
      collection(pdb, "chains"),
      where("active", "==", true),
    );

    await getDocs(q).then((docs) => {
      docs.forEach((doc) => {
        tmpArr.push(doc.id)
      });
    });
    console.log(tmpArr);
    commit("SET_ALLCHAINS", tmpArr.sort());
  },

  setCurrent({ state, commit }) {
    //console.log(state.yearmon);
    const docRef = doc(db, state.yearmon + "/beepr");

    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        let current = docSnap.data();
        //console.log(current);
        commit("SET_CURRENT", current)
      }
    });
  },

  setCat1Stats({ state, commit }) {
    //console.log(state.yearmon);
    const docRef = doc(db, "stats/cat1");

    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        let current = docSnap.data();
        commit("SET_CAT1STATS", current)
      }
    });
  },

  setBeeprDetails({ dispatch, state, commit }) {
    //console.log(state);
    if (state.selectedBeeprPath != "") {
      let docRef = {};
      if (state.selectedBeeprPath.startsWith("Cat")) {
        docRef = doc(db, state.yearmon + "/beepr/" + state.level + "/" + state.selectedBeeprPath);
      } else {
        let tmp = state.selectedBeeprPath.split(":");
        //commit("SET_SELECTED_PATH_SUFFIX", tmp[1]);
        docRef = doc(db, state.yearmon + "/beepr/" + tmp[0] + "/" + tmp[1]);
      }

      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          let current = docSnap.data();
          //console.log(current);
          dispatch("getPPM", current);
          commit("SET_NEW_CURRENT", current);
        }
      });
    }
  },

  setNewCurrent({ state, commit, dispatch }) {
    console.log(state.yearmon);
    if (state.selectedPath != "") {
      let docRef = {};
      if (state.selectedPath.startsWith("Cat")) {
        docRef = doc(db, state.yearmon + "/" + state.client + "/" + state.level + "/" + state.selectedPath);
      } else {
        let tmp = state.selectedPath.split(":");
        commit("SET_SELECTED_PATH_SUFFIX", tmp[1]);
        docRef = doc(db, state.yearmon + "/" + state.client + "/" + tmp[0] + "/" + tmp[1]);
        dispatch("setNotFound", state.yearmon + "/" + state.client + "/" + tmp[0] + "/" + tmp[1] + "-notfound");
        dispatch("setNoPrice", state.yearmon + "/" + state.client + "/" + tmp[0] + "/" + tmp[1] + "-noprice");
      }

      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          let current = docSnap.data();
          //console.log(current);
          //console.log(state.selectedPathSuffix);
          //dispatch("getPPM", current[state.selectedPathSuffix]);
          commit("SET_NEW_CURRENT", current)
        }
      });
    }
  },

  async setBrochureCurrent({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      if (state.selectedPath != "") {
        let docRef = {};
        let tmp = state.selectedPath.split(":");
        commit("SET_SELECTED_PATH_SUFFIX", tmp[1]);
        docRef = doc(db, state.yearmon + "/" + state.client + "/" + tmp[0] + "/" + tmp[1]);

        getDoc(docRef).then((docSnap) => {
          if (docSnap.exists()) {
            let current = docSnap.data();
            dispatch("setPPB", current[state.selectedPathSuffix]).then(() => {
              commit("SET_NEW_CURRENT", current)
              resolve()
            });
          }
        });
      }
    })
  },

  async setHistoryCurrent({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      if (state.selectedPath != "") {
        let docRef = {};
        let tmp = state.selectedPath.split(":");
        commit("SET_SELECTED_PATH_SUFFIX", tmp[1]);
        docRef = doc(db, state.yearmon + "/" + state.client + "/" + tmp[0] + "/" + tmp[1]);

        getDoc(docRef).then((docSnap) => {
          if (docSnap.exists()) {
            let current = docSnap.data();
            dispatch("setHistory", current[state.selectedPathSuffix]).then(() => {
              commit("SET_NEW_CURRENT", current)
              resolve()
            });
          }
        });
      }
    })
  },

  async setShortHistoryCurrent({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      if (state.selectedPath != "") {
        let docRef = {};
        let tmp = state.selectedPath.split(":");
        commit("SET_SELECTED_PATH_SUFFIX", tmp[1]);
        docRef = doc(db, state.yearmon + "/" + state.client + "/" + tmp[0] + "/" + tmp[1]);

        getDoc(docRef).then((docSnap) => {
          if (docSnap.exists()) {
            let current = docSnap.data();
            //console.log(current);
            dispatch("setShortHistory", current[state.selectedPathSuffix]).then(() => {
              commit("SET_NEW_CURRENT", current)
              resolve()
            });
          }
        });
      }
    })
  },

  setNoPrice({ commit }, path) {
    let docRef = doc(db, path);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        let current = docSnap.data();
        commit("SET_NO_PRICE", current)
      }
    });
  },

  setNotFound({ commit }, path) {
    let docRef = doc(db, path);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        let current = docSnap.data();
        commit("SET_NOT_FOUND", current)
      }
    });
  },

  setData({ state, commit }, type) {
    if (state.selectedPath != "") {
      let docRef = {};
      if (state.selectedPath.startsWith("Cat")) {
        docRef = doc(db, state.yearmon + "/" + state.client + "/" + state.level + "/" + state.selectedPath + "-" + type);
      } else {
        let tmp = state.selectedPath.split(":");
        commit("SET_SELECTED_PATH_SUFFIX", tmp[1]);
        docRef = doc(db, state.yearmon + "/" + state.client + "/" + tmp[0] + "/" + tmp[1] + "-" + type);
      }

      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          let current = docSnap.data();
          commit("SET_" + type.toUpperCase(), current)
        }
        commit("SET_LOADING", false)
      });
    }
  },

  async setPPB({ commit }, list) {
    let promises = [];
    //console.log(list);

    return new Promise((resolve, reject) => {
      list.forEach((p) => {
        promises.push(new Promise(function (resolve, reject) {
          const docRef = doc(pdb, "pricebrochure/" + p.ean);
          getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
              let ppb = docSnap.data();
              resolve({ [docSnap.id]: ppb });
            } else {
              //console.log("Could not find: " + ean);
              resolve();
            }
          });
        }));
      })

      Promise.all(promises).then((data) => {
        const filteredArray = data.filter(Boolean);
        var object = filteredArray.reduce(
          (obj, item) => Object.assign(obj, { [Object.keys(item)]: Object.values(item)[0] }), {});
        //console.log(object);
        commit("SET_PPB", object)
        resolve();
      });
    })
  },

  async setHistory({ commit }, list) {
    let promises = [];
    //console.log(list);

    return new Promise((resolve, reject) => {
      list.forEach((p) => {
        promises.push(new Promise(function (resolve, reject) {
          const docRef = doc(db, "weekpricelong/" + p.ean);
          getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
              let history = docSnap.data();
              resolve({ [docSnap.id]: history });
            } else {
              //console.log("Could not find: " + ean);
              resolve();
            }
          });
        }));
      })

      Promise.all(promises).then((data) => {
        const filteredArray = data.filter(Boolean);
        var object = filteredArray.reduce(
          (obj, item) => Object.assign(obj, { [Object.keys(item)]: Object.values(item)[0] }), {});
        //console.log(object);
        commit("SET_HISTORY", object)
        resolve();
      });
    })
  },

  async setShortHistory({ commit }, list) {
    let promises = [];
    //console.log(list);

    return new Promise((resolve, reject) => {
      list.forEach((p) => {
        promises.push(new Promise(function (resolve, reject) {
          const docRef = doc(db, "weekpriceshort/" + p.ean);
          getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
              let history = docSnap.data();
              //console.log(history);
              let use = true;
              if (history && history["chains"]) {
                history["chains"].forEach((c) => {
                  //console.log(c);
                  if(Object.keys(history[c]).length < 4) {
                    use = false;
                  }
                })
              }
              if(history == undefined || history == null || history == {} || !use) {
                resolve();
              } else {
                resolve({ [docSnap.id]: history });
              }
            } else {
              //console.log("Could not find: " + p.ean);
              resolve();
            }
          });
        }));
      })

      Promise.all(promises).then((data) => {
        const filteredArray = data.filter(Boolean);
        var object = filteredArray.reduce(
          (obj, item) => Object.assign(obj, { [Object.keys(item)]: Object.values(item)[0] }), {});
        //console.log(object);
        commit("SET_SHORTHISTORY", object)
        resolve();
      });
    })
  },

  async getPPM({ commit }, ean) {
    return new Promise((resolve, reject) => {

      const docRef = doc(pdb, "pricepermonth/" + ean);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          let ppm = docSnap.data();
          commit("SET_PPM", { ean: ean, ppm: ppm })
          resolve();
        } else {
          console.log("Could not find: " + ean);
          resolve();
        }
      });
    });
  },

  async setPrices({ state, commit }) {
    const querySnapshot = await getDocs(collection(db, state.yearmon + "/beepr/report"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());
      let payload = { id: doc.id, data: doc.data().prices };
      commit("ADD_PRICE", payload);
    });
  },

  setProductPrices({ commit }, ean) {
    const docRef = doc(pdb, "pricemap/" + ean);
    return new Promise((resolve, reject) => {
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          let prices = docSnap.data();

          if (prices && prices.prices) {

            const sortable = Object.entries(prices.prices)
              .sort(([, a], [, b]) => a.X.localeCompare(b.X))
              .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

            //console.log(sortable)

            let last = sortable[Object.keys(sortable)[Object.keys(sortable).length - 1]];
            let newLast = { ...last }
            //console.log(last);
            newLast.X = today;
            prices.prices[today] = newLast;
            //console.log(prices.prices);
            commit("SET_PRODUCT_PRICES", prices)
            resolve({});
          } else {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    });
  },

  async setProduct({ commit }, ean) {
    const prodRef = doc(pdb, "products", ean);
    getDoc(prodRef).then((docSnap) => {
      if (docSnap.exists()) {
        let product = docSnap.data();
        commit("SET_PRODUCT", product);
      }
    });
  },

  async setProducts({ dispatch, commit }, ean) {
    console.log("setting products")
    commit("SET_LOADING", true);
    const prodRef = doc(pdb, "products", ean);
    getDoc(prodRef).then((docSnap) => {
      if (docSnap.exists()) {
        let product = docSnap.data();
        commit("SET_PRODUCT", product);
        dispatch("loadProductAlternatives", product).then((alts) => {
          //console.log(alts.length);
          //console.log(Object.keys(state.ppm))
          commit("SET_PRODUCTS", alts);
          commit("SET_LOADING", false);
        });
      }
    });
  },

  async setProductsFromPath({ dispatch, commit }, path) {
    dispatch("loadProductPath", path).then((alts) => {
      commit("SET_PRODUCTS", alts);
      commit("SET_LOADING", false);
    });
},

async setProductsFromBrand({ dispatch, commit }, brand) {
  dispatch("loadProductBrand", brand).then((alts) => {
    commit("SET_PRODUCTS", alts);
    commit("SET_LOADING", false);
  });
},

  async setProductsFromList({ dispatch, commit }, list) {
    console.log("Loading data from list: " + list)
    commit("SET_LOADING", true);
    dispatch("loadProductList", list).then((alts) => {
      //console.log(alts)
      commit("SET_PRODUCTS", alts);
      commit("SET_LOADING", false);
    });
  },

  async loadProductAlternatives({ dispatch, commit }, product) {
    //console.log("Calling loadAlt with " + product)
    let promises = [];

    return new Promise((resolve, reject) => {
      //let pathalternatives = [];

      //console.log("Getting alternatives from Path mapping");
      if (product.Path == "" || product.Path == "////") {
        resolve([]);
      } else {
        commit("SET_COMPTYPE", "Kategori: " + product.Path)
        //If nothing, try our category mapping
        let q2 = query(
          collection(pdb, "products"),
          where("Path", "==", product.Path),
          //where("LowestPrice", ">", 0),
          limit(500)
        );

        getDocs(q2).then((docs) => {
          docs.forEach((doc) => {
            let altdata = doc.data();
            let tmp = pricePerUnit(
              altdata.LowestPrice,
              altdata.Units,
              altdata.Uom
            );
            if (altdata.Prices) {
              altdata.chains = Object.keys(altdata.Prices);
            } else {
              altdata.chains = [];
            }
            altdata.PPU = tmp[0];
            altdata.NormalizedUnit = tmp[1];
            altdata.NormalizedUom = tmp[2];

            promises.push(new Promise(function (resolve, reject) {
              dispatch("getPPM", doc.id).then(() => {
                //console.log("IS it loaded? : " + Object.keys(state.ppm).length)
                resolve(altdata)
                //pathalternatives.push(altdata);
              })
            }));
          });
          Promise.all(promises).then((pathAlts) => {
            //console.log("Resolving load alts");
            //console.log(pathAlts.length);
            //console.log(pathalternatives.length);
            resolve(pathAlts);
          });
        });
      }
      //});
      //);
    });
  },

  async loadProductPath({ dispatch, commit }, path) {
    let promises = [];

    return new Promise((resolve, reject) => {
        commit("SET_COMPTYPE", "Kategori: " + path)
        let q2 = query(
          collection(pdb, "products"),
          where("Path", "==", path),
          limit(500)
        );

        getDocs(q2).then((docs) => {
          docs.forEach((doc) => {
            let altdata = doc.data();
            let tmp = pricePerUnit(
              altdata.LowestPrice,
              altdata.Units,
              altdata.Uom
            );
            if (altdata.Prices) {
              altdata.chains = Object.keys(altdata.Prices);
            } else {
              altdata.chains = [];
            }
            altdata.PPU = tmp[0];
            altdata.NormalizedUnit = tmp[1];
            altdata.NormalizedUom = tmp[2];

            promises.push(new Promise(function (resolve, reject) {
              dispatch("getPPM", doc.id).then(() => {
                resolve(altdata)
              })
            }));
          });
          Promise.all(promises).then((pathAlts) => {
            resolve(pathAlts);
          });
        });
      
    });
  },

  async loadProductBrand({ dispatch, commit }, brand) {
    let promises = [];

    return new Promise((resolve, reject) => {
        commit("SET_COMPTYPE", "Kategori: " + brand)
        let q2 = query(
          collection(pdb, "products"),
          where("Brand", "==", brand),
          limit(500)
        );

        getDocs(q2).then((docs) => {
          docs.forEach((doc) => {
            let altdata = doc.data();
            let tmp = pricePerUnit(
              altdata.LowestPrice,
              altdata.Units,
              altdata.Uom
            );
            if (altdata.Prices) {
              altdata.chains = Object.keys(altdata.Prices);
            } else {
              altdata.chains = [];
            }
            altdata.PPU = tmp[0];
            altdata.NormalizedUnit = tmp[1];
            altdata.NormalizedUom = tmp[2];

            promises.push(new Promise(function (resolve, reject) {
              dispatch("getPPM", doc.id).then(() => {
                resolve(altdata)
              })
            }));
          });
          Promise.all(promises).then((pathAlts) => {
            resolve(pathAlts);
          });
        });
      
    });
  },

  async loadProductList({ dispatch, commit }, list) {
    let prepromises = [];
    let promises = [];

    //commit("SET_COMPTYPE", "Kategori: " + product.Path)

    return new Promise((resolve, reject) => {
      list.forEach((ean) => {
        //console.log(ean)
        let prodRef = doc(pdb, "products", ean);
        prepromises.push(new Promise(function (resolve, reject) {
          getDoc(prodRef).then((docSnap) => {
            if (docSnap.exists()) {
              let altdata = docSnap.data();
              //console.log(altdata)
              let tmp = pricePerUnit(
                altdata.LowestPrice,
                altdata.Units,
                altdata.Uom
              );
              if (altdata.Prices) {
                altdata.chains = Object.keys(altdata.Prices);
              } else {
                altdata.chains = [];
              }
              altdata.PPU = tmp[0];
              altdata.NormalizedUnit = tmp[1];
              altdata.NormalizedUom = tmp[2];

              promises.push(new Promise(function (resolve, reject) {
                dispatch("getPPM", docSnap.id).then(() => {
                  resolve(altdata)
                })
              }));
              resolve()
            } else {
              resolve(false)
            }
          })
        }))
      });

      Promise.all(prepromises).then(() => {
       Promise.all(promises).then((pathAlts) => {
        //console.log(promises);
        //console.log(pathAlts)
        resolve(pathAlts);
        });
      });

    });
  },

  cleanUp({ commit }) {
    commit("CLEANUP");
  },

  resetPrices({ commit }) {
    commit("RESET_PRICES");
  },

  resetCurrent({ commit }) {
    commit("RESET_CURRENT");
  },

  resetNewCurrent({ commit }) {
    commit("RESET_NEW_CURRENT");
  },

};

const mutations = {
  SET_ALLCHAINS(state, data) {
    state.allchains = data;
  },
  SET_LOADING(state, data) {
    state.loading = data;
  },
  CLEAR_PPM(state) {
    state.ppm = {};
  },
  CLEAR_PPB(state) {
    state.ppb = {};
  },
  CLEAR_HISTORY(state) {
    state.history = {};
  },
  CLEAR_SHORTHISTORY(state) {
    state.shorthistory = {};
  },
  SET_PPM(state, data) {
    state.ppm[data.ean] = data.ppm;
  },
  SET_PPB(state, data) {
    state.ppb = data;
  },
  SET_HISTORY(state, data) {
    state.history = data;
  },
  SET_SHORTHISTORY(state, data) {
    state.shorthistory = data;
  },
  SET_COMPTYPE(state, data) {
    state.comptype = data;
  },
  SET_PRODUCTS(state, data) {
    state.products = data;
  },
  SET_PRODUCT(state, data) {
    state.product = data;
  },
  SET_ALLOWED_EAN(state, data) {
    state.allowedEAN = data;
  },
  SET_LEVEL(state, data) {
    state.level = data;
  },
  SET_YEARMON(state, data) {
    state.yearmon = data;
  },
  SET_SELECTED_BEEPR_PATH(state, data) {
    state.selectedBeeprPath = data;
  },
  SET_SELECTED_PATH(state, data) {
    state.selectedPath = data;
  },
  SET_SELECTED_PATH_SUFFIX(state, data) {
    state.selectedPathSuffix = data;
  },
  SET_CURRENT(state, data) {
    state.current = data;
  },
  SET_NEW_CURRENT(state, data) {
    state.newcurrent = data;
  },
  SET_NO_PRICE(state, data) {
    state.noprice = data;
  },
  SET_NOT_FOUND(state, data) {
    state.notfound = data;
  },
  SET_OFFER(state, data) {
    state.offer = data;
  },
  SET_LOWER(state, data) {
    state.lower = data;
  },
  SET_HIGHER(state, data) {
    state.higher = data;
  },
  SET_NEW(state, data) {
    state.new = data;
  },
  SET_GONE(state, data) {
    state.gone = data;
  },
  SET_CAT1STATS(state, data) {
    state.cat1Stats = data;
  },
  SET_PRODUCT_PRICES(state, data) {
    state.productprices = data;
  },
  ADD_PRICE(state, data) {
    state.prices[data.id] = data.data;
  },
  SET_CLIENT(state, data) {
    state.client = data;
  },
  SET_CLIENT_DATA(state, data) {
    state.clientData = data;
  },
  SET_BEEPR_DATA(state, data) {
    state.beeprData = data;
  },
  CLEANUP(state) {
    state.current = [];
    state.clientData = {};
    state.client = "";
  },
  RESET_CURRENT(state) {
    state.current = [];
  },
  RESET_NEW_CURRENT(state) {
    state.newcurrent = [];
  },
  RESET_PRICES(state) {
    state.prices = {};
  },

};
export default {
  state,
  getters,
  actions,
  mutations,
};


function pricePerUnit(price, unit, uom) {
  let ppu = 0.0;
  let normU = unit;
  let norm = uom;
  switch (uom) {
    case "kg":
      ppu = price / unit;
      break;
    case "gr":
      ppu = (price / unit) * 1000;
      normU = unit / 1000;
      norm = "kg";
      break;
    case "g":
      ppu = (price / unit) * 1000;
      normU = unit / 1000;
      norm = "kg";
      break;

    case "ltr":
    case "l":
      ppu = price / unit;
      norm = "l";
      break;
    case "dl":
      ppu = (price / unit) * 10;
      normU = unit / 10;
      norm = "l";
      break;
    case "cl":
      ppu = (price / unit) * 100;
      normU = unit / 100;
      norm = "l";
      break;
    case "ml":
      ppu = (price / unit) * 1000;
      normU = unit / 1000;
      norm = "l";
      break;

    default:
      ppu = price / unit;
      break;
  }

  let normUFixed = normU ? Intl.NumberFormat("da-DK", { minimumFractionDigits: 1, maximumFractionDigits: 2, }).format(normU.toFixed(2)) : "0,00"
  return [ppu, normUFixed, norm];
}


/*
  loadProductAlternatives({ commit }, product) {
    console.log("Calling loadAlt with " + product)
    return new Promise((resolve, reject) => {
      let ingalternatives = [];
      let pathalternatives = [];
      let seen = [];
      let promises = [];
      let ingredientName = {};

      //First try ingredient mapping
      let q = query(
        collection(pdb, "ingredient"),
        where("EANs", "array-contains", product.ID),
        limit(1)
      );

      //promises.push(
      getDocs(q).then((docs) => {
        docs.forEach((mydoc) => {
          ingredientName = mydoc.id;
          let ingredient = mydoc.data();

          ingredient.EANs.forEach((ean) => {
            if (seen.indexOf(ean) == -1) {
              seen.push(ean);
              let altdata;
              const prodRef = doc(pdb, "products", ean);
              promises.push(
                getDoc(prodRef).then((docSnap) => {
                  if (docSnap.exists()) {
                    altdata = docSnap.data();

                    if(altdata.LowestPrice > 0) {
                      let tmp = pricePerUnit(
                        altdata.LowestPrice,
                        altdata.Units,
                        altdata.Uom
                      );
                      altdata.chains = Object.keys(altdata.Prices);
                      altdata.PPU = tmp[0];
                      altdata.NormalizedUnit = tmp[1];
                      altdata.NormalizedUom = tmp[2];

                      ingalternatives.push(altdata);
                    } else {
                      return false;
                    }
                  }
                  return altdata;
                })
              );
            }
          });
        });

        //console.log("Promises");
        //console.log(promises);
        Promise.all(promises).then((alts) => {
          //console.log("Product alternatives");
          //console.log(alts);
          //console.log(alts.length);
          const filteredArray = alts.filter(Boolean);
          filteredArray.sort((a, b) => a.PPU - b.PPU);
          //console.log(filteredArray)

          if (filteredArray.length == 0) {
            //console.log("Getting alternatives from Path mapping");
            if (product.Path == "" || product.Path == "////") {
              resolve([]);
            } else {
              commit("SET_COMPTYPE", "Kategori: " + product.Path)
              //If nothing, try our category mapping
              let q2 = query(
                collection(pdb, "products"),
                where("Path", "==", product.Path),
                where("LowestPrice", ">", 0),
                limit(100)
              );

              let promise = getDocs(q2).then((docs) => {
                docs.forEach((doc) => {
                  let altdata = doc.data();
                    let tmp = pricePerUnit(
                      altdata.LowestPrice,
                      altdata.Units,
                      altdata.Uom
                    );
                    altdata.chains = Object.keys(altdata.Prices);
                    altdata.PPU = tmp[0];
                    altdata.NormalizedUnit = tmp[1];
                    altdata.NormalizedUom = tmp[2];

                    pathalternatives.push(altdata);
                });
                return pathalternatives;
              });
              promise.then((pathAlts) => {
                resolve(pathAlts);
              });
            }
          } else {
            commit("SET_COMPTYPE", "Grouped: " + ingredientName)
            resolve(filteredArray);
          }
        });
      });
      //);
    });
  },

*/

<template>
  <div class="dashboard p-4">
    <div class="wrapper-button mt-3">
      <select
        v-model="selectedPath"
        id="cats"
        class="w-full lg:w-3/12 float-none lg:float-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mr-5 mb-5"
      >
        <option value="" disabled>Please select category</option>
        <option :value="'favs:' + beepruser.uid">My Favorites</option>
        <option
          v-for="eangroup in clientData.eans"
          :key="eangroup"
          :value="'eans:' + eangroup.replace(/[\W_]+/g, '')"
        >
          {{ eangroup.charAt(0).toUpperCase() + eangroup.slice(1) }}
        </option>
        <option
          v-for="path in clientData.paths"
          :key="path"
          :value="'paths:' + path.replace(/[\W_]+/g, '')"
        >
          {{ path }}
        </option>
        <option
          v-for="brand in clientData.brands"
          :key="brand"
          :value="'brands:' + brand.replace(/[\W_]+/g, '')"
        >
          {{ brand }}
        </option>
      </select>
    </div>

    <Multiselect
      v-if="chains.length > 0"
      class="w-full md:w-2/12 mb-5 float-left"
      v-model="selectedChains"
      mode="tags"
      placeholder="Filter chains"
      :close-on-select="false"
      :filter-results="true"
      :min-chars="10000"
      :resolve-on-load="false"
      :infinite="false"
      :clear-on-search="false"
      :delay="0"
      :searchable="true"
      :options="chains"
      :limit="100"
    />

    <Multiselect
      v-if="brands.length > 0"
      class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
      v-model="selectedBrands"
      mode="tags"
      placeholder="Filter brands"
      :close-on-select="false"
      :filter-results="true"
      :min-chars="10000"
      :resolve-on-load="false"
      :infinite="false"
      :clear-on-search="false"
      :delay="0"
      :searchable="true"
      :options="brands"
    />

    <Multiselect
      v-if="units.length > 1"
      class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
      v-model="selectedUnits"
      mode="tags"
      placeholder="Filter units"
      :close-on-select="false"
      :filter-results="true"
      :min-chars="10000"
      :resolve-on-load="false"
      :infinite="false"
      :clear-on-search="false"
      :delay="0"
      :searchable="true"
      :options="units"
    />

    <Multiselect
      v-if="uoms.length > 1"
      class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
      v-model="selectedUoms"
      mode="tags"
      placeholder="Filter UOM"
      :close-on-select="false"
      :filter-results="true"
      :min-chars="10000"
      :resolve-on-load="false"
      :infinite="false"
      :clear-on-search="false"
      :delay="0"
      :searchable="true"
      :options="uoms"
    />

    <div class="mt-5 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow clear-both">
      <!--       <h2 class="font-bold float-left text-lg text-gray-800 dark:text-gray-200">
        Current Brochure Offers
      </h2>
 -->
      <!--       <download-excel
        class="float-right"
        :data="formatNumbers"
        :fields="json_fields2"
        :name="selectedPathSuffix + '.xls'"
      >
        <Icon icon="iwwa:csv" height="24px" />
      </download-excel>
 -->

      <div v-if="history && Object.keys(history).length > 0 && summarySeries && summarySeries[0].data && summarySeries[0].data.length > 0">
        <div class="w-full lg:w-7/12 float-left mb-10">
          <apexchart
            width="100%"
            height="380"
            type="area"
            :options="chartOptions"
            :series="summarySeries"
          ></apexchart>
        </div>

        <div class="w-full lg:w-5/12 float-right mb-10">
          <apexchart
            width="100%"
            height="320"
            type="donut"
            :options="donutOptions"
            :series="donutSeries"
          ></apexchart>
        </div>

        <!--         <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0"
          >
            <tr>
              <th scope="col" class="uppercase px-6 py-3">#Products</th>
              <th scope="col" class="uppercase px-6 py-3">12 mnd ago</th>
              <th scope="col" class="uppercase px-6 py-3">%</th>
              <th scope="col" class="uppercase px-6 py-3">6 mnd ago</th>
              <th scope="col" class="uppercase px-6 py-3">%</th>
              <th scope="col" class="uppercase px-6 py-3">3 mnd ago</th>
              <th scope="col" class="uppercase px-6 py-3">%</th>
              <th scope="col" class="uppercase px-6 py-3">Past 4 week</th>
              <th scope="col" class="uppercase px-6 py-3">Graph</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="px-6 py-4">
                {{ total["products"] }}
              </td>
              <td class="px-6 py-4">
                {{ currFormatter.format(total["12mndago"] / 4) }}
              </td>
              <td class="px-6 py-4">
                {{ pFormatter.format((total["1mndago"]-total["12mndago"]) / total["12mndago"]) }}
              </td>

              <td class="px-6 py-4">
                {{ currFormatter.format(total["6mndago"] / 4) }}
              </td>
              <td class="px-6 py-4">
                {{ pFormatter.format((total["1mndago"]-total["6mndago"]) / total["6mndago"]) }}
              </td>

              <td class="px-6 py-4">
                {{ currFormatter.format(total["3mndago"] / 4) }}
              </td>
              <td class="px-6 py-4">
                {{ pFormatter.format((total["1mndago"]-total["3mndago"]) / total["3mndago"]) }}
              </td>

              <td class="px-6 py-4">
                {{ currFormatter.format(total["1mndago"] / 4) }}
              </td>

              <td class="px-6 py-4">
                <button
                  @click="
                    isOpen = {};
                    isOpen[items.ean] = true;
                  "
                  type="button"
                >
                  <Icon icon="codicon:graph-line" height="22px" />
                </button>
              </td>

            </tr>
          </tbody>
        </table>
 -->
      </div>


      <h2 v-if="history && Object.keys(history).length > 0" class="clear-both font-bold float-left text-lg text-gray-800 mt-2 mb-5 text-align-left">
        Products - all average prices below are based on a 4 week period
      </h2>

      <div
        v-if="history && Object.keys(history).length > 0"
        class="wrapping-table mt-10 clear-both"
      >
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0"
          >
            <tr>
              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('name')"
              >
                <svg
                  v-if="sortBy == 'name' && this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                <svg
                  v-else-if="sortBy == 'name' && !this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>
                Product
              </th>
              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('brand')"
              >
                <svg
                  v-if="sortBy == 'brand' && this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                <svg
                  v-else-if="sortBy == 'brand' && !this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                Brand
              </th>

              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('normUnit')"
              >
                <svg
                  v-if="sortBy == 'normUnit' && this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                <svg
                  v-else-if="sortBy == 'normUnit' && !this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                Units
              </th>
              <th scope="col" class="uppercase px-6 py-3">UoM</th>

              <th scope="col" class="uppercase px-6 py-3">12 mnd ago</th>
              <th scope="col" class="uppercase px-6 py-3">9 mnd ago</th>
              <th scope="col" class="uppercase px-6 py-3">6 mnd ago</th>
              <th scope="col" class="uppercase px-6 py-3">3 mnd ago</th>
              <th scope="col" class="uppercase px-6 py-3">Past 4 weeks</th>
              <th scope="col" class="uppercase px-6 py-3">Graph</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
              v-for="items in allProducts"
              :key="items.ean"
            >
              <td class="px-6 py-4">
                <a
                  v-if="
                    beepruser &&
                    beepruser.data &&
                    beepruser.data.email &&
                    beepruser.data.email.includes('@beepr.'+country)
                  "
                  class="float-left mr-2"
                  :href="'https://products.beepr.'+country+'/edit/' + items.ean"
                  target="_blank"
                >
                  <Icon icon="bytesize:edit" height="20px" />
                </a>

                <a
                  target="_blank"
                  :href="
                    'https://beepr.' +
                    country +
                    '/products/' +
                    items.ean +
                    '/' +
                    items.name
                      .toLowerCase()
                      .trim()
                      .replace(/\./g, '')
                      .replace(/ø/g, 'oe')
                      .replace(/æ/g, 'ae')
                      .replace(/å/g, 'aa')
                      .replace(/[^a-z0-9]+/g, '-')
                  "
                  >{{ items.importName ? items.importName : items.name }}</a
                >
                <br />
                <span
                  v-if="!selectedPath.includes('paths') && items.path && items.path.length > 5"
                  style="font-size: 9px; color: gray"
                  >{{ items.path }}</span
                >
              </td>

              <td class="px-6 py-4">
                {{ items.brand }}
              </td>

              <td class="px-6 py-4">
                {{ Intl.NumberFormat("da-DK", {}).format(items.normUnit) }}
              </td>
              <td class="px-6 py-4">
                {{ items.normUom }}
              </td>

              <td class="px-6 py-4">
                {{ currFormatter.format(items.ppbObj["12mndago"] / 4) }}
              </td>
              <td class="px-6 py-4" :style="(items.ppbObj['9mndago'] - items.ppbObj['12mndago']) > 0 ? 'color:red' : (items.ppbObj['9mndago'] - items.ppbObj['12mndago']) == 0 ? '' : 'color:green'">
                {{ currFormatter.format(items.ppbObj["9mndago"] / 4) }} 
                ({{ pFormatter.format((items.ppbObj["9mndago"] - items.ppbObj["12mndago"]) / items.ppbObj["12mndago"]) }})
              </td>
              <td class="px-6 py-4" :style="(items.ppbObj['6mndago'] - items.ppbObj['9mndago']) > 0 ? 'color:red' : (items.ppbObj['6mndago'] - items.ppbObj['9mndago']) == 0 ? '' : 'color:green'">
                {{ currFormatter.format(items.ppbObj["6mndago"] / 4) }}
                ({{ pFormatter.format((items.ppbObj["6mndago"] - items.ppbObj["9mndago"]) / items.ppbObj["9mndago"]) }})
              </td>
              <td class="px-6 py-4" :style="(items.ppbObj['3mndago'] - items.ppbObj['6mndago']) > 0 ? 'color:red' : (items.ppbObj['3mndago'] - items.ppbObj['6mndago']) == 0 ? '' : 'color:green'">
                {{ currFormatter.format(items.ppbObj["3mndago"] / 4) }}
                ({{ pFormatter.format((items.ppbObj["3mndago"] - items.ppbObj["6mndago"]) / items.ppbObj["6mndago"]) }})
              </td>
              <td class="px-6 py-4" :style="(items.ppbObj['1mndago'] - items.ppbObj['3mndago']) > 0 ? 'color:red' : (items.ppbObj['1mndago'] - items.ppbObj['3mndago']) == 0 ? '' : 'color:green'">
                {{ currFormatter.format(items.ppbObj["1mndago"] / 4) }}
                ({{ pFormatter.format((items.ppbObj["1mndago"] - items.ppbObj["3mndago"]) / items.ppbObj["3mndago"]) }})
              </td>

              <td class="px-6 py-4">
                <button
                  @click="
                    labels = Object.keys(history[items.ean]['all']);
                    series = getPrices(history[items.ean]);
                    isOpen = {};
                    isOpen[items.ean] = true;
                  "
                  type="button"
                >
                  <Icon icon="codicon:graph-line" height="22px" />
                </button>
                  <chart-modal
                  :isOpen="isOpen"
                  @closemodal="closeModal(items.ean)"
                  :labels="labels"
                  :series="series"
                  :ean="items.ean"
                  :name="items.name"
                />
              </td>

            </tr>
          </tbody>
        </table>
      </div>

      <div v-else>No products have been available throughout the entire period</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import ChartModal from "./components/chartmodal.vue";
import Multiselect from "@vueform/multiselect";


export default {
  name: "Brochure",
  data() {
    return {
      currProducts: [],
      brands: [],
      chains: [],
      units: [],
      uoms: [],
      weeks: [],
      selectedBrands: [],
      selectedChains: [],
      selectedUnits: [],
      selectedUoms: [],
      selectedWeeks: [],
      sortBy: "name",
      sortAsc: true,
      labels: [],
      series: [],
      isOpen: {},
      total: {},
      chainCount: {},
      currFormatter: new Intl.NumberFormat("da-DK", {
        style: "currency",
        currency: "DKK",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      pFormatter: new Intl.NumberFormat("da-DK", {
        style: "percent",
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
      }),

      summarySeries: [ { name:"", data: []}],
      donutSeries: [],
      donutOptions: {
        chart: {
          height: 320,
          type: "donut",
        },
        colors: [
          "#800000",
          "#3CB44B",
          "#000075",
          "#F032E6",
          "#E6194B",
          "#F58231",
          "#A9A9A9",
          "#FFE119",
          "#BFEF45",
          "#42D4F4",
          "#4363D8",
          "#911EB4",
          "#FABED4",
          "#FFD8B1",
          "#469990",
          "#AAFFC3",
          "#000000",
          "#DCBEFF",
          "#9A6324",
          "#808000",
          "#FFFAC8",
        ],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        title: {
          text: "Products per chain",
        },
      },

      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#00E396"],
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            if (dataPointIndex == 0) {
              return "<div></div>";
            } else {
              return (
                '<div class="p-2"><div class="text-xs text-gray-700 uppercase bg-gray-50">Development since last period </div>' +
                "<div>" +
                Intl.NumberFormat("da-DK", {
                  style: "percent",
                  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
                }).format(
                  (series[seriesIndex][dataPointIndex] - series[seriesIndex][dataPointIndex - 1]) /
                    series[seriesIndex][dataPointIndex - 1]
                ) +
                "</div>" +
                "</div>"
              );
            }
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
              ? Intl.NumberFormat("da-DK", {
                  style: "currency",
                  currency: "DKK",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(val)
              : val;
          },
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Avg price for the selected products",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["12 mnd ago", "9 mnd ago", "6 mnd ago", "3 mnd ago", "Past 4 weeks"],
        },
        yaxis: {
          title: {
            text: "Avg price over 4 week period",
          },
          labels: {
            formatter: function (val) {
              return val
                ? Intl.NumberFormat("da-DK", {
                    style: "currency",
                    currency: "DKK",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(val)
                : val;
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
  },
  components: {
    Icon,
    Multiselect,
    ChartModal,
  },
  watch: {
    clientData(newData, old) {
      if (newData) {
        if (this.beepruser && this.beepruser.uid && this.beepruser.data && this.beepruser.data.favs && this.beepruser.data.favs.length > 0 ) {
          this.selectedPath = "favs:" + this.beepruser.uid;
        }else if (newData.eans && newData.eans.length > 0) {
          this.selectedPath = "eans:" + newData.eans[0].replace(/[\W_]+/g, "");
        } else if (newData.paths && newData.paths.length > 0) {
          this.selectedPath = "paths:" + newData.paths[0].replace(/[\W_]+/g, "");
        } else if (newData.brands && newData.brands.length > 0) {
          this.selectedPath = "brands:" + newData.brands[0].replace(/[\W_]+/g, "");
        }
      }
    },
  },
  methods: {
    getPrices(history) {
      let output = [];
      let tmpobj = [];
      Object.keys(history)
        .sort()
        .forEach((key) => {
          Object.keys(history[key]).forEach((key2) => {
            if(key != "chains") {
              let upper = key[0].toUpperCase() +key.slice(1);
              (tmpobj[upper] = tmpobj[upper] || []).push(history[key][key2]);
            }
          });
        });

      Object.keys(tmpobj)
        .sort()
        .forEach((key) => {
          //console.log(key);
          //console.log(tmpobj[key]);
          let out = { name: key, data: tmpobj[key] };
          //if (tmpobj[key][0] > 0 || tmpobj[key][25] > 0) {
            output.push(out);
          //}
        });
        //console.log(output);
      return output;
      //return [{name: 'test', data: [prices['2022-06-01']['Minkobmand'],9,9,9,9,9,9,9]}]
    },

    closeModal(ean) {
      this.isOpen[ean] = false;
      this.series = [];
      this.labels = [];
    },
    updateSort(newSort) {
      if (newSort == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = newSort;
        this.sortAsc = true;
      }
    },
    filterChains(chains) {
      return chains.filter((a) => a !== "postme");
    },
  },
  computed: {
    ...mapGetters({
      country: "country",
      beepruser: "beepruser",
      client: "client",
      clientData: "clientData",
      newcurrent: "newcurrent",
      notfound: "notfound",
      noprice: "noprice",
      currYear: "currYear",
      currMon: "currMon",
      productprices: "productprices",
      selectedPathSuffix: "selectedPathSuffix",
      history: "history",
      currWeek: "currWeek",
    }),

    json_fields2() {
      let tmp = {
        EAN: "ean",
        Product: "name",
        Brand: "brand",
        "Lowest price": "price",
        Offer: "offer",
        Unit: "normUnit",
        "Unit of measure": "normUom",
        "Price-per-unit": "ppu",
        Chains: "chains",
      };
      return tmp;
    },

    formatNumbers() {
      let products = JSON.parse(JSON.stringify(this.allProducts));
      products.map((p) => {
        p.ean = `'${p.ean}'`;
        p.name = p.importName ? p.importName : p.name;
        //p.Name = `${p.Name +" "}`;
        //p.Brand = `${p.Brand + " "}`;
        //p.chains = `${p.chains+ " "}`;
        //p.NormalizedUom = `${p.NormalizedUom+ " "}`;
        //p.LowestPrice = Math.round(p.LowestPrice * 100) / 100;
        //p.PPU = Math.round(p.PPU * 100) / 100;
        //p.NormalizedUnit = Math.round(p.NormalizedUnit * 100) / 100;
        p.price = Intl.NumberFormat("da-DK", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(p.price.toFixed(2));
        p.ppu = p.ppu
          ? Intl.NumberFormat("da-DK", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(p.ppu.toFixed(2))
          : "0,00";
        p.normUnit = p.normUnit
          ? Intl.NumberFormat("da-DK", {
              minimumFractionDigits: 1,
              maximumFractionDigits: 2,
            }).format(p.normUnit)
          : "0,00";
      });
      //console.log(products)
      return products;
    },

   /*  donutSeries() {
      return Object.values(this.chainCount);
    }, */

    selectedPath: {
      get() {
        return this.$store.state.beeprStore.selectedPath;
      },
      set(value) {
        this.$store.dispatch("setSelectedPath", value);
        this.$store.dispatch("resetNewCurrent");
        this.$store.dispatch("setHistoryCurrent");
      },
    },

    allowedEAN: {
      get() {
        return this.$store.state.beeprStore.allowedEAN;
      },
      set(value) {
        this.$store.dispatch("setAllowedEAN", value);
      },
    },

    allProducts() {
      console.log("Calling allProducts");
      let products = {};

      if (this.sortBy == "name" || this.sortBy == "brand") {
        if (this.sortAsc) {
          products = this.newcurrent[this.selectedPathSuffix]
            ? this.newcurrent[this.selectedPathSuffix].sort((a, b) =>
                a[this.sortBy].localeCompare(b[this.sortBy], "da")
              )
            : [];
        } else {
          products = this.newcurrent[this.selectedPathSuffix]
            ? this.newcurrent[this.selectedPathSuffix].sort((a, b) =>
                b[this.sortBy].localeCompare(a[this.sortBy], "da")
              )
            : [];
        }
      } else {
        if (this.sortAsc) {
          products = this.newcurrent[this.selectedPathSuffix]
            ? this.newcurrent[this.selectedPathSuffix].sort(
                (a, b) => a[this.sortBy] - b[this.sortBy]
              )
            : [];
        } else {
          products = this.newcurrent[this.selectedPathSuffix]
            ? this.newcurrent[this.selectedPathSuffix].sort(
                (a, b) => b[this.sortBy] - a[this.sortBy]
              )
            : [];
        }
      }

      //console.log(Object.keys(this.ppb).length)
      //console.log(this.history);
      if (this.history && Object.keys(this.history).length > 0) {
        products = products.filter((o) => Object.keys(this.history).includes(o.ean));
      }

      let tmp2 = products.map(function (obj) {
        return obj.brand;
      });
      this.brands = [...new Set(tmp2)].sort();

      tmp2 = products.map(function (obj) {
        return obj.normUnit;
      });
      this.units = [...new Set(tmp2)].sort();

      tmp2 = products.map(function (obj) {
        return obj.normUom;
      });
      this.uoms = [...new Set(tmp2)].sort();

      /*       tmp2 = products.map(function (obj) {
        return [...obj.chains];
      });
      this.chains = [...new Set(tmp2.flat())].sort();
 */
      if (this.selectedChains.length > 0) {
        products = products.filter((o) => o.chains.some((r) => this.selectedChains.includes(r)));
      }

      if (this.selectedBrands.length > 0) {
        products = products.filter((o) => this.selectedBrands.includes(o.brand));
      }

      if (this.selectedUnits.length > 0) {
        products = products.filter((o) => this.selectedUnits.includes(o.normUnit));
      }

      if (this.selectedUoms.length > 0) {
        products = products.filter((o) => this.selectedUoms.includes(o.normUom));
      }

      let total = { products: 0 };
      let totalbyean = {};
      let mychains = {};

      products.forEach((p, i) => {
        let ppbObj = {};
        if (this.history[p.ean]) {
          total["products"]++;
          ppbObj = this.history[p.ean];

          this.history[p.ean]["chains"].forEach((c) => {
            mychains[c] = mychains[c] + 1 || 1;
          });

          let start = this.currWeek - 56;
          let end = this.currWeek;
          let year = this.currYear;
          //console.log(this.currWeek + " : " + year + " : " + start + ": " + end);

          let counter = 0;
          for (let index = start; index < end; index++) {
            counter++;
            let useW = index;
            let useY = year;
            if (index < 1) {
              useW = 52 + index;
              useY = year - 1;
            }
            let useI = String(useY) + String(useW).padStart(2, "0");

            if (counter < 5) {
              total["12mndago"] =
                total["12mndago"] + this.history[p.ean]["all"][useI] ||
                this.history[p.ean]["all"][useI];
              totalbyean[p.ean] = totalbyean[p.ean] || {};
              totalbyean[p.ean]["12mndago"] =
                totalbyean[p.ean]["12mndago"] + this.history[p.ean]["all"][useI] ||
                this.history[p.ean]["all"][useI];
              //console.log(useI);
            } else if (counter > 12 && counter < 17) {
              total["9mndago"] =
                total["9mndago"] + this.history[p.ean]["all"][useI] ||
                this.history[p.ean]["all"][useI];
              totalbyean[p.ean]["9mndago"] =
                totalbyean[p.ean]["9mndago"] + this.history[p.ean]["all"][useI] ||
                this.history[p.ean]["all"][useI];
            } else if (counter > 25 && counter < 30) {
              total["6mndago"] =
                total["6mndago"] + this.history[p.ean]["all"][useI] ||
                this.history[p.ean]["all"][useI];
              totalbyean[p.ean]["6mndago"] =
                totalbyean[p.ean]["6mndago"] + this.history[p.ean]["all"][useI] ||
                this.history[p.ean]["all"][useI];
            } else if (counter > 37 && counter < 42) {
              total["3mndago"] =
                total["3mndago"] + this.history[p.ean]["all"][useI] ||
                this.history[p.ean]["all"][useI];
              totalbyean[p.ean]["3mndago"] =
                totalbyean[p.ean]["3mndago"] + this.history[p.ean]["all"][useI] ||
                this.history[p.ean]["all"][useI];
            } else if (counter > 52) {
              //console.log(useI);
              total["1mndago"] =
                total["1mndago"] + this.history[p.ean]["all"][useI] ||
                this.history[p.ean]["all"][useI];
              totalbyean[p.ean]["1mndago"] =
                totalbyean[p.ean]["1mndago"] + this.history[p.ean]["all"][useI] ||
                this.history[p.ean]["all"][useI];
            }
          }
          products[i]["ppbObj"] = totalbyean[p.ean];
        }
      });

      const sortable = Object.entries(mychains)
    .sort(([,a],[,b]) => b-a)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});


      this.chainCount = sortable;
      this.chains = Object.keys(sortable);
      //console.log(sortable);

      this.donutSeries = Object.values(this.chainCount);
      this.donutOptions = {...this.donutOptions, ...{
          labels: this.chains.map(function(e) { e = e[0].toUpperCase() + e.slice(1); return e;}),
        }
      };

      if (total["products"] > 0) {
        this.summarySeries = [
        {
          name: "Avg prices for " + total["products"] + " products",
          data: [
            total["12mndago"] / 4 / total["products"],
            total["9mndago"] / 4 / total["products"],
            total["6mndago"] / 4 / total["products"],
            total["3mndago"] / 4 / total["products"],
            total["1mndago"] / 4 / total["products"],
          ],
        },
      ];
      } else {
        this.summarySeries = [ { name:"", data: []}];
      }

      window.dispatchEvent(new Event('resize'))

      //console.log(this.summarySeries)
      //console.log(total);
      this.total = total;

      this.currProducts = products;
      return products;
    },
  },
  mounted() {
    this.$store.dispatch("setHistoryCurrent");
    this.$store.commit("CLEAR_HISTORY");
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

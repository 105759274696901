<template>
  <!-- App -->
  <div v-if="inactive" class="flex font-quicksand dark:bg-gray-900">
    <div class="flex-auto w-full overflow-auto h-screen" id="body-scroll">
      <Header />

      <div class="mx-auto bg-white mt-36 md:mt-18 text-center">
        This user/client is inactive. Please contact mm@beepr.dk for details.
      </div>
    </div>
  </div>
  
  <div v-else class="flex font-quicksand dark:bg-gray-900">
    <div class="flex-auto w-full overflow-auto h-screen" id="body-scroll">
      <Header />

      <div class="mx-auto bg-white mt-24 md:mt-18">
        <router-view />
      </div>
<!--       <Footer />
 -->    </div>
  </div>
  <!-- end app -->
  <login-modal />
  <chart-modal />
  <percent-chart-modal />
  <product-modal />

</template>

<script>
  // vue Components
  //import Sidebar from "@/components/Sidebar";
  import Header from "@/components/Header";
  import Footer from "@/components/Footer";
  // npm-js
  //import Scrollbar from "smooth-scrollbar";
  import LoginModal from "./views/LoginModal.vue";
  import ChartModal from "./views/components/chartmodal.vue";
  import PercentChartModal from "./views/components/percentchartmodal.vue";
  import ProductModal from "./views/ProductModal.vue";
  import { auth, analytics } from "./firebaseDb";
  import { mapGetters } from "vuex";

  export default {
    name: "App",
    data() {
      return {
        inactive: false,
      };
    },

    components: {
      Header,
      Footer,
      LoginModal,
      ChartModal,
      PercentChartModal,
      ProductModal
      //Sidebar,
    },
/*     metaInfo: {
    changed(metaInfo) {
      analytics.logEvent("page_view");
      analytics.logEvent("screen_view", {
        screen_name: metaInfo.title,
      });
    }
  },
 */    mounted: function () {
    auth.auth.onAuthStateChanged((user) => {
      //TODO perhaps check store if this is loaded already from main.js and not call again?
      this.$store.dispatch("fetchUser", user).then((beepruser) => {
        if (user && user.uid) {
          this.$store.dispatch("setClientData").then((clientdata) => {
            console.log(clientdata);
            if (clientdata && clientdata.inactive) {
              console.log("inactive");
              this.inactive = true;
            }
          });

          this.$store.dispatch("setAllChains")
          console.log(beepruser);
          
          analytics.setUserId(beepruser.uid);
          analytics.setUserProperties({
            account_email: beepruser.email,
            account_name:  beepruser.name,
            account_client: beepruser.client
          });

          if (beepruser.client == "beepr") {
            console.log("Should set new");
            this.$store.dispatch("setNewCurrent")
            this.$router.push({ name: "report"});
          } else {
            //this.$store.dispatch("setCurrent")
          }
          //this.$store.dispatch("setLists", user.uid);
        }
      });
    });

      //Scrollbar.init(document.querySelector("#body-scroll"));

      setTimeout(() => {
        var alert_dis = document.querySelectorAll(".alert-dismiss");
        alert_dis.forEach((x) =>
          x.addEventListener("click", function () {
            x.parentElement.classList.add("hidden");
          })
        );
      }, 100);
    },
    
    computed: {
    ...mapGetters({
      beepruser: "beepruser",
      //client: "client",
      //clientData: "clientData"
    }),
    },
  };
</script>
